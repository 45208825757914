import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  items: [
    {
      id: 'structured-doctors',
      heading: 'Doctors',
      description:
        'Each team is led by a board-certified doctor with advanced training in functional medicine, nutrition, and comprehensive lab work.',
      illustration: <IllustrativeIcon name="doctor" />
    },
    {
      id: 'structured-health-coaches',
      heading: 'Health Coaches',
      description:
        'Your health coach is dedicated to keeping you on track and easing the addition of lifestyle changes that help optimize healthy movement, nutrition, sleep, and stress management.',
      illustration: <IllustrativeIcon name="personal" />
    },
    {
      id: 'structured-care-managers',
      heading: 'Care Managers',
      description:
        'Your care manager makes sure things run smoothly with scheduling, coordinating labs, and insurance or FSA/HSA questions.',
      illustration: <IllustrativeIcon name="teamCollab" />
    }
  ]
};

export default Data;
