import React from 'react';
import { useProviders } from '../hooks/use-providers';

import {
  Layout,
  Module,
  TextLockup,
  Text,
  Structured,
  ProviderGrid,
  StandardCarousel,
  Locations,
  HalfAndHalf,
  NumberedList,
  Button
} from '../redesign';
import {
  textLockup,
  structured,
  conditions,
  locations,
  halfAndHalfList
} from '../redesign/content/doctors';

export default function Doctors() {
  const {
    providers,
    centersOptions,
    providerOptions,
    setFilter
  } = useProviders();

  return (
    <Layout>
      <Module marginTop={['xl', 'xxl']} animate>
        <TextLockup alignment={textLockup.alignment}>
          <Text
            variant="p3b"
            className="text-lockup-eyebrow"
            color="dark-green"
          >
            {textLockup.eyebrow}
          </Text>
          <Text
            variant="h2a"
            className="text-lockup-headline"
            color="dark-green"
          >
            {textLockup.headline}
          </Text>
          <Text
            variant="p2a"
            className="text-lockup-description"
            color="dark-green"
            style={{ maxWidth: '800px' }}
          >
            {textLockup.description}
          </Text>
        </TextLockup>
      </Module>
      <Module animate>
        <Structured {...structured} />
      </Module>
      <Module theme="darkGreen">
        <ProviderGrid
          headline="Meet your future Care Team."
          providers={providers}
          filter={{
            locations: centersOptions,
            roles: providerOptions,
            onFilterChange: setFilter
          }}
          ctaTrackingLabelMore="Show more button on Provider's page - provider grid"
          ctaTrackingLabelLess="Show less button on Provider's page - provider grid"
        />
      </Module>
      <Module>
        <HalfAndHalf image={halfAndHalfList.image}>
          <TextLockup alignment={['center', 'left']}>
            <Text variant="h2a" className="mb-md">
              {halfAndHalfList.headline}
            </Text>
            <NumberedList items={halfAndHalfList.items} />
            <Button
              color="sage"
              destination={halfAndHalfList.cta.destination}
              className="mt-md"
              trackingLabel={halfAndHalfList.cta.ctaTrackingLabel}
            >
              {halfAndHalfList.cta.label}
            </Button>
          </TextLockup>
        </HalfAndHalf>
      </Module>
      <Module animate>
        <StandardCarousel {...conditions} />
      </Module>
      <Module animate>
        <Locations
          headline={locations.headline}
          supportingCopy={locations.supportingCopy}
          left={locations.left}
          right={locations.right}
        />
      </Module>
    </Layout>
  );
}
